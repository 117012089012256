/**
 * 2. Structure
 * ----------------------------------------------------------------------------
 */
.inner-wide {
  max-width: 1080px;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
}

.site {
  overflow: hidden;
  position: relative;
}

.site-content {
  padding-left: 3vw;
  padding-right: 3vw;
}

.site-content {
  padding-bottom: 2.5em;
  padding-top: 2.5em;
}

.site-main {
  margin-bottom: 3.33333em;
}
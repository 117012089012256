// Fonts
$font-primary: 'Karla', sans-serif;
$font-secondary: 'Crimson Text', serif;

// Colors
$color-accent: #2311ea;
$gray-darkest: #1d1d1d;
$gray-dark: #333;
$gray: #666;
$gray-light: #aaa;
$gray-lighter: #eee;
$yellow: #fffac4;

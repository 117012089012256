/* Generated with help from https://google-webfonts-helper.herokuapp.com */

/* crimson-text-regular - latin */
@font-face {
    font-family: 'Crimson Text';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/crimson-text-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Crimson Text Regular'), local('CrimsonText-Regular'),
         url('../fonts/crimson-text-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/crimson-text-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/crimson-text-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/crimson-text-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/crimson-text-v10-latin-regular.svg#CrimsonText') format('svg'); /* Legacy iOS */
}
  
/* crimson-text-italic - latin */
@font-face {
    font-family: 'Crimson Text';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/crimson-text-v10-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Crimson Text Italic'), local('CrimsonText-Italic'),
         url('../fonts/crimson-text-v10-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/crimson-text-v10-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/crimson-text-v10-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/crimson-text-v10-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/crimson-text-v10-latin-italic.svg#CrimsonText') format('svg'); /* Legacy iOS */
}
  
/* crimson-text-600 - latin */
@font-face {
    font-family: 'Crimson Text';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/crimson-text-v10-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Crimson Text SemiBold'), local('CrimsonText-SemiBold'),
         url('../fonts/crimson-text-v10-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/crimson-text-v10-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/crimson-text-v10-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/crimson-text-v10-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/crimson-text-v10-latin-600.svg#CrimsonText') format('svg'); /* Legacy iOS */
}
  
/* crimson-text-600italic - latin */
@font-face {
    font-family: 'Crimson Text';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/crimson-text-v10-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local('Crimson Text SemiBold Italic'), local('CrimsonText-SemiBoldItalic'),
         url('../fonts/crimson-text-v10-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/crimson-text-v10-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/crimson-text-v10-latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/crimson-text-v10-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/crimson-text-v10-latin-600italic.svg#CrimsonText') format('svg'); /* Legacy iOS */
}

/* karla-regular - latin */
@font-face {
    font-family: 'Karla';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/karla-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Karla'), local('Karla-Regular'),
         url('../fonts/karla-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/karla-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/karla-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/karla-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/karla-v13-latin-regular.svg#Karla') format('svg'); /* Legacy iOS */
}

/* karla-700 - latin */
@font-face {
    font-family: 'Karla';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/karla-v13-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Karla Bold'), local('Karla-Bold'),
         url('../fonts/karla-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/karla-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/karla-v13-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/karla-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/karla-v13-latin-700.svg#Karla') format('svg'); /* Legacy iOS */
}

/* karla-italic - latin */
@font-face {
    font-family: 'Karla';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/karla-v13-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Karla Italic'), local('Karla-Italic'),
         url('../fonts/karla-v13-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/karla-v13-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/karla-v13-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/karla-v13-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/karla-v13-latin-italic.svg#Karla') format('svg'); /* Legacy iOS */
}

/* karla-700italic - latin */
@font-face {
    font-family: 'Karla';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/karla-v13-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Karla Bold Italic'), local('Karla-BoldItalic'),
         url('../fonts/karla-v13-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/karla-v13-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/karla-v13-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/karla-v13-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/karla-v13-latin-700italic.svg#Karla') format('svg'); /* Legacy iOS */
}